(function ($) {
  //------------------------------------------------------//
  // ScrollMagic | Define the scroll magic controller
  //------------------------------------------------------//
  var controller = new ScrollMagic.Controller();
  //------------------------------------------------------//
  // animate homepage logic
  //------------------------------------------------------//
  $('.ACF-kenmerken #bubbles * ').each(function () {
    var minNumber = -10;
    var maxNumber = 10;

    function randomNumberFromRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    var randomNumber = randomNumberFromRange(minNumber, maxNumber);



    var randomBubbleMove = new TimelineMax({
        repeat: -1,
        yoyo: true
      })
      .to(this, 3, {
        y: randomNumber
      }, 0)
      .to(this, 3, {
        x: randomNumber
      }, 0);
  });

  var timelineBubbles = new TimelineMax()
    .staggerFrom('.ACF-kenmerken #main-bubbles > *', 0.6, {
      scale: 0,
      transformOrigin: "center",
      stagger: 0.1
    }, 0)
    .staggerFrom('.ACF-kenmerken #bubbles *', 0.3, {
      scale: 0,
      transformOrigin: "center",
      stagger: 0.1
    }, '-=0.6');



  var sceneBubbles = new ScrollMagic.Scene({
      triggerElement: '.ACF-kenmerken',
      duration: -1, // Setting this to a number changes antimation to tirgger on the scroll position
      tweenChanges: true, // False makes it so animation is set on scroll, true is that is happens smoothly after scroll
      offset: -200, // Define a offset in pixels. Nagative number move the scroll up (trigger ealier)
      // reverse: false, // Should the animation play also when you scroll back up? Default is true
    })
    .setTween(timelineBubbles) // Tells what the time line should be
    .addTo(controller);
  // END animate homepage logic -------------------------------------//
  //------------------------------------------------------//

})(jQuery); // Fully reference jQuery after this point.
