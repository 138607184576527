
(function ($) {
  var alloyMainContent = $('main');
  var alloyAllHeaders = $('h2, h3, h4', alloyMainContent);
  function convertToSlug(Text) {
    return Text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  // console.warn(alloyAllHeaders);
  alloyAllHeaders.each(function (index, value) {
    var alloyCurrentItem = $(this);
    alloyCurrentItem.attr('id', convertToSlug(alloyCurrentItem.text()) + '-' + index);
    alloyCurrentItem.append('<a class="alloy-headline-anchor" href="#' + convertToSlug(alloyCurrentItem.text()) + '-' + index + '">#</a>');
  });
})(jQuery); // Fully reference jQuery after this point.
